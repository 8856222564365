﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export function initHeader() {

    const menuTop = document.getElementsByClassName("menu-top")[0];
    const menuOpen = document.getElementsByClassName("menu-open-icon")[0];
    const menuClose = document.getElementsByClassName("close-menu")[0];
    const logo = document.getElementsByClassName("logo")[0];
    const dareWord = document.getElementsByClassName("dareWord")[0];
    const dareBolt = document.getElementsByClassName("dareBolt")[0];
    const pageTitle = document.getElementsByClassName("page-title-wrapper")[0];
    const contactLinks = document.getElementsByClassName("contact-links-main")[0];

    const menuItemContainers = document.getElementsByClassName("nav-item-container");
    const menuItems = document.getElementsByClassName("menu-link");

    menuTop.addEventListener("touchstart", handleMenu);
    menuTop.addEventListener("click", handleMenu);

    const activeMenuItem = document.querySelectorAll('.menu-link[aria-current="page"]');
    Array.from(menuItems).forEach(menuItem => {
        menuItem.addEventListener("mouseenter", function () {
            const bg = `bg-${menuItem.dataset.bg}`;
            const bgBolt = `bg-${menuItem.dataset.bolt}`;
            const menuHover = menuItem.closest(".menu-hover");
            menuHover.classList.remove("bg-black");
            menuHover.classList.remove("bg-red");
            menuHover.classList.remove("bg-blue");
            Array.from(menuItemContainers).forEach(menuItemContainer => {
                menuItemContainer.classList.remove("bg-bolt");
                menuItemContainer.classList.remove("bg-bolt-yellow");
            });
            menuItem.closest(".nav-item-container").classList.add(bgBolt);
            menuHover.classList.add(bg);
            menuHover.classList.remove("bg-bolt");
            menuHover.classList.remove("bg-bolt-yellow");
        });

        menuItem.addEventListener("mouseleave", function () {
            Array.from(menuItemContainers).forEach(menuItemContainer => {
                menuItemContainer.classList.remove("bg-bolt");
                menuItemContainer.classList.remove("bg-bolt-yellow");
            });
            const menuHover = menuItem.closest(".menu-hover");
            menuHover.classList.remove("bg-black");
            menuHover.classList.remove("bg-red");
            menuHover.classList.remove("bg-blue");

            if (activeMenuItem.length > 0) {
                const bg = `bg-${activeMenuItem[0].dataset.bg}`;
                const bgBolt = `bg-${activeMenuItem[0].dataset.bolt}`;
                menuHover.classList.add(bg);
                menuHover.classList.add(bgBolt);
            }

        });
    });

    const headerTl = gsap.timeline();

    ScrollTrigger.create({
        animation: headerTl,
        trigger: ".top-overlay",
        start: "bottom",
        end: "bottom",
        scrub: true,
        onEnter: () => {
            logo.classList.remove("position-absolute");
            dareWord.classList.add("d-none");
            dareBolt.classList.remove("d-none");
            if (menuTop.dataset.logo !== "dark") {
                menuTop.classList.add("mix-mode");
            }
            if (pageTitle !== undefined) {
                pageTitle.classList.add("page-title-fixed");
            }
            menuTop.classList.remove("position-absolute");
        },
        onEnterBack: () => {
            logo.classList.add("position-absolute");
            dareWord.classList.remove("d-none");
            dareBolt.classList.add("d-none");
            if (menuTop.dataset.logo !== "dark") {
                menuTop.classList.remove("mix-mode");
            }
            if (pageTitle !== undefined) {
                pageTitle.classList.remove("page-title-fixed");
            }
            menuTop.classList.add("position-absolute");
        }
    });

    ScrollTrigger.create({
        animation: headerTl,
        trigger: ".top-overlay",
        start: "bottom bottom-=50px",
        end: "bottom bottom-=50px",
        scrub: true,
        onEnter: () => {
            contactLinks.classList.add("mix-mode");
        },
        onEnterBack: () => {
            contactLinks.classList.remove("mix-mode");
        }
    });


    function handleMenu(evt) {
        evt.preventDefault();
        const navWrapper = document.getElementsByClassName("nav-wrapper")[0];

        if (document.body.style.overflow === "hidden") {
            document.body.style.overflow = "inherit";
        } else {
            document.body.style.overflow = "hidden";
        }

        if (menuTop.classList.contains("mix-mode")) {
            menuTop.classList.remove("mix-mode");
        } else {
            menuTop.classList.add("mix-mode");
        }

        navWrapper.classList.toggle("d-none");
        menuClose.classList.toggle("d-none");
        menuOpen.classList.toggle("d-none");
    }

};