﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function initAnimateTextOnScroll() {
    
    const textContainer = gsap.utils.toArray(".text-animate");
    textContainer.forEach(container => {
        const tl = gsap.timeline();
        tl.fromTo(container.querySelectorAll(".moveUp"),
            {
                marginTop: "1.5rem"
            },
            {
                duration: 2,
                marginTop: "-1.5rem"
            });

        tl.fromTo(container.querySelectorAll(".moveDown"),
            {
                marginTop: "-1.5rem"
            },
            {
                duration: 2,
                marginTop: "1.5rem"
            }, "-=2");

        ScrollTrigger.create({
            animation: tl,
            trigger: container,
            start: "top center",
            end: "bottom center",
            scrub: 1.5
        });
    });
};