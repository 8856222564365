﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export function initFooter() {
    const footerTl = gsap.timeline();

    footerTl.fromTo(".footer-scroll",
        {
            opacity: 0
        },
        {
            duration: 3,
            x: "0vh",
            visibility: "visible",
            opacity: 1
        });

    ScrollTrigger.create({
        animation: footerTl,
        trigger: ".footer-scroll",
        toggleActions: "play none none none"
    });
};