﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { initAnimateTextOnScroll } from "./components/animate-text-on-scroll";
import { initHeader } from "./components/header";
import { initFooter } from "./components/footer";
import { initCookies } from "./components/cookie-banner";
import "../styles/main.scss";

gsap.registerPlugin(ScrollTrigger);
initAnimateTextOnScroll();
initHeader();
initFooter();
initCookies();

window.addEventListener("resize", scrollTriggerRefresh);

function scrollTriggerRefresh() {
    setTimeout(function() {
            ScrollTrigger.refresh();
        },
        300);
}
