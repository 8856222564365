﻿export function initCookies() {
    const accept = document.getElementById("accept");
    const reject = document.getElementById("reject");

    if ((accept !== undefined && accept !== null) && (reject !== undefined && reject !== null)) {
        accept.addEventListener("touchstart", handleAccept);
        accept.addEventListener("click", handleAccept);

        reject.addEventListener("touchstart", handleReject);
        reject.addEventListener("click", handleReject);
    }

    function handleAccept(evt) {
        evt.preventDefault();
        setCookie("consent", 1, 365);
        window.location.reload();
    }

    function handleReject(evt) {
        evt.preventDefault();
        setCookie("consent", 0, 365);
        window.location.reload();
    }

    const checkbox = document.getElementById("jTrackingConsent");

    if (checkbox !== undefined && checkbox !== null) {
        checkbox.addEventListener("change",
            (event) => {
                const label = document.getElementById("jStateLabel");
                if (event.currentTarget.checked) {
                    setCookie("consent", 1, 365);
                    window.location.reload();
                    label.innerText = "Active";
                } else {
                    setCookie("consent", 0, 365);
                    window.location.reload();
                    label.innerText = "Inactive";
                }
            });
    }
};


export function setCookie(cname, cValue, exDays) {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}